import Vue from 'vue'
import Vuex from 'vuex'
import {getLocalStorageLocaleKey, getLocalStorageTokenKey, isLocalStorageEnabled} from "../utils/localStorage";
import Axios from "axios";

Vue.use(Vuex)

export default {
    state: {
        loading: false,

        company: null,
        user: null,
        token: null,
        locales: null,
        locale: 'nl',
        states: null,

        faqLink: 'https://signly.nl/veel-gestelde-vragen/',
        termsLink: 'https://signly.nl/wp-content/uploads/2021/12/Nederland_ICT_Voorwaarden_2014.pdf'
    },
    mutations: {

        setLoading(state, loading) {
            state.loading = loading;
        },

        setCompany(state, company) {
            state.company = company;
        },

        setUser(state, user) {
            state.user = user;
        },

        setLocale(state, locale) {
            state.locale = locale;
        },

        setLocales(state, locales) {
            state.locales = locales;
        },

        // states = statuses from api
        setStates(state, states) {
            state.states = states;
        },

        setToken(state, token) {
            state.token = token;

            if (isLocalStorageEnabled()) {
                if (!token) {
                    localStorage.removeItem(getLocalStorageTokenKey());
                } else {
                    localStorage.setItem(getLocalStorageTokenKey(), token);
                }
            }
        },
    },
    actions: {

        login(context, {user, token}) {
            context.commit('setUser', user);
            context.commit('setCompany', null);
            context.commit('setToken', token);
            context.commit('setLoading', false);
        },

        logout(context) {
            context.commit('setUser', null);
            context.commit('setCompany', null);
            context.commit('setToken', null);
            context.commit('setLoading', false);
        },

        setLoading(context, loading) {
            context.commit('setLoading', loading);
        },

        states(context, states) {
            context.commit('setStates', states);
        },

        company(context, company) {
            context.commit('setCompany', company);
        },

        async updateCompany(context) {
            try {
                const response = await Axios.get('/api/company', {
                    headers: {
                        Authorization: `Bearer ${context.state.token}`
                    }
                });
                context.commit('setCompany', response.data);
            } catch (exception) {
                console.error(exception);
            }
        },

        setLocale(context, locale) {
            if (isLocalStorageEnabled()) {
                localStorage.setItem(getLocalStorageLocaleKey(context.state), locale);
            }

            context.commit('setLocale', locale);
        },

    },
    modules: {}
}
