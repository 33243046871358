export default function(formData, fields) {
    for (const fieldIndex in fields) {
        const field = fields[fieldIndex];

        formData.append('fields[' + fieldIndex + '][x]', field.x);
        formData.append('fields[' + fieldIndex + '][y]', field.y);
        formData.append('fields[' + fieldIndex + '][width]', field.width);
        formData.append('fields[' + fieldIndex + '][height]', field.height);
        formData.append('fields[' + fieldIndex + '][type]', field.type);
        formData.append('fields[' + fieldIndex + '][page]', field.page);

        if (field?.options?.rules?.length) {
            for (const ruleIndex in field.options.rules) {
                formData.append('fields[' + fieldIndex + '][options][rules][' + ruleIndex + ']', field.options.rules[ruleIndex]);
            }
        }
    }
}