<template>
    <div class="group-box">
        <div class="group-box-header cursor-pointer" @click="visible = !visible">
            <slot name="title" />

            <button
                class="btn btn-sm btn-primary ms-auto"
            >
                <font-awesome-icon icon="chevron-down" v-if="visible" />
                <font-awesome-icon icon="chevron-up" v-else />
            </button>
        </div>

        <div v-if="visible" class="group-box-content">
            <div v-for="(field, fieldIndex) of fields" :key="fieldIndex">
                <div
                    v-if="field.type === 'field'"
                    class="variable-box"
                    draggable="true"
                    @dragstart="fieldDragStart($event, field.field.config)"
                >
                    <div>
                        {{ field.field.name }}
                        <small v-if="field.field.subName" class="variable-value">{{ field.field.subName }}</small>
                    </div>

                    <button class="btn btn-primary btn-sm"
                            style="margin-left: auto"
                            v-if="field.field.config.renderType === 'paraph'"
                            v-tooltip="'Paraaf automatisch toevoegen aan meerdere pagina\'s'"
                            @click="$emit('add-paraph-to-all-pages', field.field.config)"
                    >
                        <font-awesome-icon icon="file-alt" />
                    </button>
                </div>

                <div v-if="field.type === 'title'" class="group-title">
                    {{ field.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: {
            open: {
                type: Boolean,
                default: false,
            },

            fields: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                visible: this.open,
            };
        },

        watch: {
            open() {
                this.visible = this.open;
            },
        },

        methods: {
            fieldDragStart(event, variable) {
                event.dataTransfer.setData('text', JSON.stringify({
                    variable,
                    action: 'add',
                    startX: event.clientX,
                    startY: event.clientY,
                }));
            },
        },
    };

</script>

<style scoped>

    .group-box {
        margin-bottom: 15px;
    }

    .group-box .variable-box {
        margin-bottom: 2px;
    }

    .group-box-header {
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        text-align: left;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 2px;
        font-weight: bold;
        user-select: none;
    }

    .group-box-content {
        padding-left: 15px;
    }

    .group-title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
    }

</style>