<template>
    <div>
        <FullscreenLoading v-if="loading && showTemplateSelect"></FullscreenLoading>

        <Modal :show="showTemplateSelect"
               @close="hide"
               :custom_modal_class="'modal-xl'"
        >
            <div slot="header">
                <div class="modal-header">
                    <h5 class="modal-title mt-0">Document toevoegen vanuit template</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide"><span aria-hidden="true">×</span></button>
                </div>
            </div>

            <div slot="body">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="summary-box mb-3 text-left variables-custom">
                            <h2 class="title-heading-left mb-3">Templates</h2>

                            <div class="templates">
                                <div
                                    class="template-box"
                                    draggable="true"
                                    v-for="template of templates.data" :key="template.id"
                                    :class="{'selected': currentTemplate && template.id === currentTemplate.template.id}"
                                    @click="showTemplate(template)"
                                >
                                    {{ template.name }}
                                    <small class="d-block" v-if="getTemplateFieldCount(template)">{{ getTemplateFieldCount(template) }} formulier marker{{ getTemplateFieldCount(template) > 1 ? 's' : '' }}</small>
                                </div>
                            </div>

                            <div class="alert alert-info" v-if="!loading && templates.data.length <= 0">
                                Je hebt nog geen documenten opgeslagen als template.

                                <br/><br/>
                                <router-link target="_blank" :to="{ name: 'Templates' }">Klik hier om documenten als template toe te voegen.</router-link>
                            </div>
                        </div>

                        <Pagination :pagination="templates" @change="getTemplates()" class="templates-pagination"/>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="all-container">
                            <div ref="pageContainer" class="page-container" v-if="currentTemplate">
                                <div id="viewer" class="pdfViewer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="col-12">
                <div style="padding-left: 0.5rem">
                    <button type="button" class="btn btn-outline-secondary float-start" data-dismiss="modal" @click="hide">Sluiten</button>

                    <button
                        v-if="currentTemplate"
                        type="button"
                        class="btn btn-primary float-end"
                        data-dismiss="modal"
                        @click="$emit('select', currentTemplate); hide()"
                    >
                        Document toevoegen
                    </button>
                </div>
            </div>

        </Modal>

        <div @click="show">
            <slot name="label"/>
        </div>
    </div>
</template>

<script>

    import Modal from '@/components/Modal';
    import pdfjsLib from 'pdfjs-dist/build/pdf';
    import {PDFViewer} from 'pdfjs-dist/web/pdf_viewer';
    import 'pdfjs-dist/web/pdf_viewer.css';
    import FullscreenLoading from './FullscreenLoading.vue';
    import Pagination from './Pagination.vue';

    export default {

        components: {
            Pagination,
            FullscreenLoading,
            Modal
        },

        data() {
            return {
                templates: {
                    data: []
                },

                showTemplateSelect: false,
                currentTemplate: null,

                pdfViewer: null,
                loading: false
            };
        },

        mounted() {
            window.addEventListener('resize', this.resizePageContainer);
            this.hide();
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.resizePageContainer);

            if (this.pdfViewer) {
                this.pdfViewer.eventBus.off('pagesloaded', this.resizePageContainer);
            }
        },

        methods: {
            async showTemplate(template) {
                this.reset();

                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.api_url + `/api/templates/${template.id}/file`, {
                        responseType: 'blob',
                        transformResponse: [function (data) {
                            return new window.Blob([data], {
                                type: 'application/pdf'
                            });
                        }]
                    });

                    const file = new File([response.data], template.id + '.pdf', {
                        type: 'application/pdf'
                    });
                    const filename = file.name.substring(0, file.name.lastIndexOf('.'));

                    this.currentTemplate = {
                        template,
                        file: {
                            file: file,
                            filename,
                            last_modified: file.lastModified,
                            size: file.size,
                            src: URL.createObjectURL(file),
                            config: {
                                variables: template.variables || [],
                                fields: template.fields || [],
                            }
                        }
                    };
                } catch (exception) {
                    this.reset();
                    console.error(exception);

                    this.loading = false;
                    return;
                }

                this.loading = false;


                this.$nextTick(async () => {
                    let container = this.$refs.pageContainer;

                    this.pdfViewer = new PDFViewer({
                        container: container,
                        scale: 'page-width',
                        renderInteractiveForms: false, // no forms
                        textLayerMode: 0 // disable
                    });

                    let pdf = await pdfjsLib.getDocument(this.currentTemplate.file.src);

                    this.pdfViewer.eventBus.on('pagesloaded', this.resizePageContainer);

                    this.pdfViewer.setDocument(pdf);
                });
            },

            reset() {
                this.currentTemplate = null;

                if (this.pdfViewer) {
                    this.pdfViewer.eventBus.off('pagesloaded', this.resizePageContainer);
                }

                this.pdfViewer = null;
            },

            show() {
                this.showTemplateSelect = true;
                this.getTemplates();
            },

            hide() {
                this.showTemplateSelect = false;

                this.reset();
            },

            async getTemplates(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/templates', {
                        params: {
                            page: page || this.templates.current_page,
                            order: 'id,desc',
                            limit: 100
                        }
                    });

                    this.templates = response.data;
                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            },

            resizePageContainer() {
                if (!this.pdfViewer) {
                    return;
                }

                this.pdfViewer.currentScaleValue = 'page-fit';
            },

            getTemplateFieldCount(template) {
                return (template.fields?.length || 0) + (template.variables?.length || 0);
            }
        }
    };

</script>

<style scoped>

    .templates {
        max-height: 60vh;
        overflow: auto;
    }

    .template-box {
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        text-align: left;
        margin-bottom: 5px;
        cursor: grab;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        word-wrap: break-word;

        &.selected {
            background: #0e6ace;
            color: #fff;
        }
    }

    .page-container {
        margin: auto;
        width: 100%;
        background-color: #c1c1c1;
        max-height: 70vh;
        overflow: auto;
        position: relative;
    }

    .all-container {
        width: 100%;
        max-height: 70vh;
        position: relative;
    }

</style>

<style>

    .templates-pagination .pagination {
        margin-top: 10px;
    }

</style>